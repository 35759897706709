import { css } from '@emotion/react'
import { rgba } from 'polished'

import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

const inputWidth = (width: string) => {
  switch (width) {
    default:
      return css`
        flex-basis: 100%;
      `
    case 'Half':
      return css`
        flex-basis: calc(50% - 0.5 * var(--gap));
        ${mq().s} {
          flex-basis: 100%;
        }
      `
    case 'Third':
      return css`
        flex-basis: calc(33.3% - var(--gap));
        ${mq().s} {
          flex-basis: 100%;
        }
      `
    case 'Quarter':
      return css`
        flex-basis: calc(25% - 1.5 * var(--gap));
        ${mq().s} {
          flex-basis: calc(50% - 0.5 * var(--gap));
        }
      `
  }
}

export const fieldStyles = {
  container: (width?: string | null) => css`
    position: relative;
    flex: 1;
    ${inputWidth(width || 'Full')}
    --text-color: ${colors.gray30};
  `,
  inputBase: css`
    background-color: ${colors.gray95};
    border-radius: 0.25em;
    border: 1px solid transparent;
    box-sizing: border-box;
    overflow: hidden;
    transition:
      border-color 300ms ease,
      background-color 300ms ease;
    div:focus-within > & {
      border-color: ${colors.teal};
    }
  `,
  input: css`
    box-sizing: border-box;
    border: none;
    padding: 1.5em 1em 0.5em;
    line-height: 1.333;
    width: 100%;
    color: var(--text-color);
    background-color: transparent;
    /* hide text in webkit */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: var(--text-color) !important;
      box-shadow: 0 0 0 10em ${colors.gray95} inset !important;
      caret-color: var(--text-color) !important;
    }
  `,
  label: css`
    position: absolute;
    pointer-events: none;
    z-index: 2;
    top: 1.5835em;
    left: 1em;
    color: ${colors.gray60};
    max-width: calc(100% - 2em);
    line-height: 1.333;
    padding-right: 2px;
    transform: translate3d(0, -0.5em, 0);
    transition: transform 200ms ease;
    transform-origin: 0 0;
    /* font-style: italic; */
    font-weight: 400;
  `,
  shrink: css`
    transform: translate3d(0, -1.25em, 0) scale3d(0.75, 0.75, 1);
    font-weight: 500;
    max-width: calc(133.333% - 2.667em);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    div:focus-within > & {
      color: ${colors.teal};
    }
  `,
  required: css`
    &::after {
      content: '*';
      display: inline-block;
      font-size: 75%;
      margin-left: 0.125em;
      transform: translateY(-0.125em);
    }
  `,
  checkbox: css`
    width: 1.125em;
    height: 1.125em;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25em;
    box-sizing: border-box;
    margin-top: 0.1em;
    margin-right: 0.333em;
    background-color: transparent;
    border: 1.5px solid rgba(0, 0, 0, 0.5);
    transition: all 200ms ease;
    &::after {
      content: '';
      width: 0.25em;
      height: 0.5em;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform-origin: 75% 50%;
      transform: rotate(45deg);
      visibility: hidden;
    }
    @media (hover: hover) {
      div:hover > & {
        background-color: ${rgba(colors.teal, 0.333)};
      }
    }
    &[data-checked='true'] {
      background-color: ${colors.teal};
      border-color: ${colors.tealDark};
      @media (hover: hover) {
        div:hover > & {
          background-color: ${colors.tealMid};
        }
      }
      &::after {
        visibility: visible;
      }
    }
  `,
}
